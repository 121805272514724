import GATSBY_COMPILED_MDX from "/opt/build/repo/src/mdx/terms-and-conditions.mdx";
import Box from "@mui/material/Box/Box.js";
import Typography from "@mui/material/Typography/Typography.js";
import React from 'react';
import {MDXProvider} from '@mdx-js/react';
import Container from '../components/app/Container';
import SEO from '../components/app/SEO';
import {jsx as _jsx} from "react/jsx-runtime";
import {jsxs as _jsxs} from "react/jsx-runtime";
const headings = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].reduce((acc, tag) => ({
  ...acc,
  [tag]: props => _jsx(Typography, {
    variant: tag,
    gutterBottom: true,
    fontWeight: "bold",
    ...props
  })
}), {});
const components = {
  ...headings,
  p: props => _jsx(Typography, {
    variant: "body1",
    ...props
  })
};
const Template = ({data, children}) => {
  var _data$mdx, _data$mdx$frontmatter, _data$mdx2, _data$mdx2$frontmatte;
  return _jsx(MDXProvider, {
    components: components,
    children: _jsx(Container, {
      children: _jsxs(Box, {
        marginY: 6,
        children: [((_data$mdx = data.mdx) === null || _data$mdx === void 0 ? void 0 : (_data$mdx$frontmatter = _data$mdx.frontmatter) === null || _data$mdx$frontmatter === void 0 ? void 0 : _data$mdx$frontmatter.title) && _jsx(SEO, {
          title: (_data$mdx2 = data.mdx) === null || _data$mdx2 === void 0 ? void 0 : (_data$mdx2$frontmatte = _data$mdx2.frontmatter) === null || _data$mdx2$frontmatte === void 0 ? void 0 : _data$mdx2$frontmatte.title
        }), children]
      })
    })
  });
};
const pageQuery = "4127958738";
Template
export default function GatsbyMDXWrapper(props) {
  return React.createElement(Template, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
